import React from 'react';
import PropTypes from 'prop-types';

// UI
// =============================================================================
import Container from 'common/src/components/UI/Container';

// Components
// =============================================================================
import Box from 'common/src/components/Box';

// Blocks
// =============================================================================
import FeaturesBlock from './blocks/Features';
import PromoBlock from './blocks/Promo';

// Styles
// =============================================================================
import SectionAreaWrapper, { SectionArea } from './FeaturesWithPromo.style';

// Colours
// =============================================================================
import brand from 'common/src/theme/landing/brand';

// FeaturesWithPromo
// =============================================================================
const FeaturesWithPromo = ({
  SectionWrapperStyle,
  RowStyle,
  RowFixedStyle,
  ColStyle,
  ColOneStyle,
  ColTwoStyle,
  fwpLogo,
  fwpHeading,
  fwpText,
  fwpButton,
  fwpDataFeatures,
}) => {
  return (
    <Box {...SectionWrapperStyle} id="features_promo">
      <Container fullWidth noGutter>
        <Box {...RowStyle} {...RowFixedStyle}>
          <Box {...ColStyle} {...ColOneStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <PromoBlock
                  fwpLogo={fwpLogo}
                  fwpHeading={fwpHeading}
                  fwpText={fwpText}
                  fwpButton={fwpButton}
                />
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
          <Box {...ColStyle} {...ColTwoStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <FeaturesBlock fwpDataFeatures={fwpDataFeatures} />
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

FeaturesWithPromo.propTypes = {
  SectionWrapperStyle: PropTypes.object,
  RowStyle: PropTypes.object,
  RowFixedStyle: PropTypes.object,
  ColStyle: PropTypes.object,
  ColOneStyle: PropTypes.object,
  ColTwoStyle: PropTypes.object,
};

FeaturesWithPromo.defaultProps = {
  SectionWrapperStyle: {
    as: 'section',
    bg: brand.brand8,
    pt: 0,
    pb: 0,
  },
  RowStyle: {
    flexBox: true,
    flexWrap: 'wrap',
    flexDirection: 'row-reverse',
    alignItems: 'top',
    justifyContent: 'center',
    height: ['auto', 'auto', 'auto', '600px', '600px'],
  },
  RowFixedStyle: {
    maxWidth: '1260px',
    m: '0 auto',
  },
  ColStyle: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    height: '100%',
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  ColOneStyle: {},
  ColTwoStyle: {
    bg: brand.brand8,
  },
};

export default FeaturesWithPromo;
