import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

// UI
// =============================================================================
import Container from 'common/src/components/UI/Container';

// Components
// =============================================================================
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';

// Blocks
// =============================================================================
import BenefitsBlock from './blocks/Benefits';
import FormBlock from './blocks/Form';

// Styles
// =============================================================================
import SectionAreaWrapper, { SectionArea } from './BenefitsWithForm.style';

// Colours
// =============================================================================
import brand from 'common/src/theme/landing/brand';

// BenefitsWithForm
// =============================================================================
const BenefitsWithForm = ({
  SectionWrapperStyle,
  RowStyle,
  ColStyle,
  ColOneStyle,
  ColTwoStyle,
  BenefitsHeadingStyle,
  bwfHeading,
  bwfDataBenefits,
  bwfFormLogo,
  bwfFormHeading,
  bwfFormPageType,
  bwfFormLink,
  bwfFormButton,
}) => {
  return (
    <Box {...SectionWrapperStyle} id="benefits_form">
      <Container fullWidth noGutter>
        <Box {...RowStyle}>
          <Box {...ColStyle} {...ColOneStyle}>
            <SectionAreaWrapper>
              <Fade left delay={20}>
                <SectionArea>
                  <Heading {...BenefitsHeadingStyle} content={bwfHeading} />
                  <BenefitsBlock dataBenefits={bwfDataBenefits} />
                </SectionArea>
              </Fade>
            </SectionAreaWrapper>
          </Box>
          <Box {...ColStyle} {...ColTwoStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <FormBlock
                  formHeading={bwfFormHeading}
                  formLogo={bwfFormLogo}
                  formButton={bwfFormButton}
                  formLink={bwfFormLink}
                  formPageType={bwfFormPageType}
                />
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

BenefitsWithForm.propTypes = {
  SectionWrapperStyle: PropTypes.object,
  RowStyle: PropTypes.object,
  ColStyle: PropTypes.object,
  ColOneStyle: PropTypes.object,
  ColTwoStyle: PropTypes.object,
  BenefitsHeadingStyle: PropTypes.object,
};

BenefitsWithForm.defaultProps = {
  SectionWrapperStyle: {
    as: 'section',
    bg: brand.brand8,
    pt: 0,
    pb: 0,
  },
  RowStyle: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'top',
    justifyContent: 'center',
    height: ['auto', 'auto', 'auto', '740px', '760px'],
  },
  ColStyle: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    height: '100%',
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ColOneStyle: {},
  ColTwoStyle: {
    bg: brand.brand8Tint90,
  },
  BenefitsHeadingStyle: {
    fontSize: ['22px', '24px', '24px', '26px', '28px'],
    fontWeight: '500',
    color: '#fff',
    letterSpacing: '0em',
    mb: '40px',
    lineHeight: '1.35',
  },
};

export default BenefitsWithForm;
