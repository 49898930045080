import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const PromoBlockWrapper = styled.div`
  text-align: center;
  position: relative;

  .SignUpBtn {
    background: ${themeGet('brand.brand6')};
    color: #fff;
    display: inline-block;
    line-height: 1;
    padding: 12px 30px;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 500;

    :hover {
      background: ${themeGet('brand.brand6Shade10')};
    }
  }
`;

export default PromoBlockWrapper;
