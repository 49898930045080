import styled from 'styled-components';
//import { themeGet } from '@styled-system/theme-get';

const SectionAreaWrapper = styled.div`
  padding: 26px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  @media (min-width: 1220px) {
    //max-width: 700px;
  }
`;

export const SectionArea = styled.div`
  position: relative;

  @media (min-width: 320px) {
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 991px) {
  }
  @media (min-width: 1220px) {
  }
`;

export default SectionAreaWrapper;
