import React from 'react';
import PropTypes from 'prop-types';

// UI
// =============================================================================
import Container from 'common/src/components/UI/Container';

// Components
// =============================================================================
import Box from 'common/src/components/Box';
import Logo from 'common/src/components/UIElements/Logo';

// Styles
// =============================================================================
import SectionAreaWrapper, { SectionArea } from './Header.style';

// Colours
// =============================================================================
import brand from 'common/src/theme/landing/brand';

// Images
// =============================================================================
import LogoImage from 'common/src/assets/image/cybertonica-logo.png';

// Header
// =============================================================================
const Header = ({
  SectionWrapperStyle,
  RowStyle,
  ColStyle,
  HeaderLogoStyle,
}) => {
  return (
    <Box {...SectionWrapperStyle} id="header">
      <Container fullWidth noGutter>
        <Box {...RowStyle}>
          <Box {...ColStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <Logo
                  logoSrc={LogoImage}
                  title="Cybertonica Ltd."
                  logoStyle={HeaderLogoStyle}
                />
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

Header.propTypes = {
  SectionWrapperStyle: PropTypes.object,
  RowStyle: PropTypes.object,
  ColStyle: PropTypes.object,
  HeaderLogoStyle: PropTypes.object,
};

Header.defaultProps = {
  SectionWrapperStyle: {
    as: 'header',
    bg: brand.brand8,
    pt: '40px',
    pb: '0',
  },
  RowStyle: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ColStyle: {
    width: '100%',
  },
  HeaderLogoStyle: {
    width: '240px',
    m: '0 auto',
    display: 'inline-block',
  },
};

export default Header;
