import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

// UI
// =============================================================================
import Container from 'common/src/components/UI/Container';

// Components
// =============================================================================
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';

// Styles
// =============================================================================
import SectionAreaWrapper, { SectionArea } from './IntroWithImage.style';

// Colours
// =============================================================================
import brand from 'common/src/theme/landing/brand';

// IntroWithImage
// =============================================================================
const IntroWithImage = ({
  SectionWrapperStyle,
  RowStyle,
  ColStyle,
  ColOneStyle,
  ColTwoStyle,
  IntroBlockHeadingStyle,
  IntroBlockTextStyle,
  IntroBlockImageStyle,
  iwiHeading,
  iwiText,
  iwiImage,
}) => {
  return (
    <Box {...SectionWrapperStyle} id="intro_image">
      <Container>
        <Fade up delay={60}>
          <Box {...RowStyle}>
            <Box {...ColStyle} {...ColOneStyle}>
              <SectionAreaWrapper>
                <SectionArea>
                  <Image
                    src={iwiImage}
                    alt="Devices"
                    {...IntroBlockImageStyle}
                  />
                </SectionArea>
              </SectionAreaWrapper>
            </Box>
            <Box {...ColStyle} {...ColTwoStyle}>
              <SectionAreaWrapper>
                <SectionArea>
                  <Heading {...IntroBlockHeadingStyle} content={iwiHeading} />
                  {iwiText ? (
                    <Text {...IntroBlockTextStyle} content={iwiText} />
                  ) : null}
                </SectionArea>
              </SectionAreaWrapper>
            </Box>
          </Box>
        </Fade>
      </Container>
    </Box>
  );
};

IntroWithImage.propTypes = {
  SectionWrapperStyle: PropTypes.object,
  RowStyle: PropTypes.object,
  ColStyle: PropTypes.object,
  ColOneStyle: PropTypes.object,
  ColTwoStyle: PropTypes.object,
  IntroBlockHeadingStyle: PropTypes.object,
  IntroBlockTextStyle: PropTypes.object,
  IntroBlockImageStyle: PropTypes.object,
};

IntroWithImage.defaultProps = {
  SectionWrapperStyle: {
    as: 'section',
    bg: '#fff',
    pt: ['20px', '20px', '30px', '30px', '60px'],
    pb: ['40px', '40px', '50px', '50px', '80px'],
  },
  RowStyle: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ColStyle: {
    width: ['100%', '100%', '100%', '100%', '100%'],
  },
  ColOneStyle: {},
  ColTwoStyle: {},
  IntroBlockHeadingStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '24px'],
    fontWeight: '500',
    color: brand.brand8,
    letterSpacing: '0em',
    mb: '20px',
    lineHeight: '1.35',
    textAlign: 'center',
    maxWidth: '900px',
    ml: 'auto',
    mr: 'auto',
  },
  IntroBlockTextStyle: {
    fontSize: ['18px', '18px', '18px', '19px', '19px'],
    color: brand.brand8,
    lineHeight: '1.6',
    textAlign: 'center',
    mb: ['30px'],
  },
  IntroBlockImageStyle: {
    maxWidth: '700px',
    m: '0 auto 30px',
    width: '100%',
  },
};

export default IntroWithImage;
