import React from 'react';
import PropTypes from 'prop-types';
//import Fade from 'react-reveal/Fade';

// Components
// =============================================================================
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import { Link } from 'gatsby';

// Common
// =============================================================================
import DesignedByShopify from '../../../../common/DesignedByShopify';

// Styles
// =============================================================================
import PromoBlockWrapper from './PromoBlock.style';

// Colours
// =============================================================================
//import brand from 'common/src/theme/landing/brand';

// PromoBlock
// =============================================================================
const PromoBlock = ({
  PromoBlockLogoStyle,
  PromotBlockHeadingStyle,
  PromoBlockTextStyle,
  PromoBlockButtonWrapperStyle,
  //PromoBlockButtonStyle,
  fwpLogo,
  fwpHeading,
  fwpText,
  fwpButton,
}) => {
  return (
    <PromoBlockWrapper>
      <Image src={fwpLogo} {...PromoBlockLogoStyle} alt="Promo Logo" />
      <Heading {...PromotBlockHeadingStyle} content={fwpHeading} />
      <Text {...PromoBlockTextStyle} content={fwpText} />
      <Box {...PromoBlockButtonWrapperStyle}>
        <Link to="#benefits_form" className="SignUpBtn">
          {fwpButton}
        </Link>
      </Box>
      {/* <Fade up delay={20}> */}
      <DesignedByShopify />
      {/* </Fade> */}
    </PromoBlockWrapper>
  );
};

PromoBlock.propTypes = {
  PromoBlockLogoStyle: PropTypes.object,
  PromotBlockHeadingStyle: PropTypes.object,
  PromoBlockTextStyle: PropTypes.object,
  PromoBlockButtonWrapperStyle: PropTypes.object,
  //PromoBlockButtonStyle: PropTypes.object,
};

PromoBlock.defaultProps = {
  PromoBlockLogoStyle: {
    maxWidth: ['60px', '60px', '70px', '80px', '90px'],
    m: '0 auto 20px',
  },
  PromotBlockHeadingStyle: {
    fontSize: ['24px', '24px', '26px', '26px', '28px'],
    fontWeight: '500',
    color: '#fff',
    textAlign: 'center',
    letterSpacing: '-0.025em',
    mb: '20px',
    lineHeight: '1.35',
  },
  PromoBlockTextStyle: {
    fontSize: ['17px', '17px', '18px', '18px', '19px'],
    color: '#fff',
    fontWeight: '400',
    textAlign: 'center',
    lineHeight: '1.6',
    m: '0 auto 20px',
    maxWidth: '300px',
  },
  PromoBlockButtonWrapperStyle: {
    maxWidth: '600px',
    width: '100%',
    m: '20px auto',
    display: 'inline-block',
  },
  // PromoBlockButtonStyle: {
  //   type: 'button',
  //   fontSize: ['15px', '17px'],
  //   fontWeight: '500',
  //   colors: 'cyber6WithBg',
  //   minHeight: '44px',
  //   pt: '0',
  //   pb: '0',
  //   pl: ['15px', '30px'],
  //   pr: ['15px', '30px'],
  // },
};

export default PromoBlock;
